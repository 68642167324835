import React, { useState } from 'react';
import * as utils from '../common/Utils.js'

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

const GameContainer = (props) => {
  let league = props.league;
	let gameID = props.gameID;
  let showPlaceholders = props.showPlaceholders;

  const game = utils.getGameForID(league.games, gameID)

  let gameTBD = false;

  let away_team;
  let home_team;
  if (game.away_team_id && !showPlaceholders) {
    away_team = utils.getTeamForID(league.teams, game.away_team_id);
    away_team.record = utils.getRecordForID(league.games, game.away_team_id)
  } else {
    const placeholderName = game.away_team_placeholder
    away_team = {
      name: placeholderName || 'TBD',
      record: '-',
      seed: ''
    };
    gameTBD = true;
  }

  if (game.home_team_id  && !showPlaceholders) {
    home_team = utils.getTeamForID(league.teams, game.home_team_id);
    home_team.record = utils.getRecordForID(league.games, game.home_team_id)
  } else {
    const placeholderName = game.home_team_placeholder
    home_team = {
      name: placeholderName || 'TBD',
      record: '-',
      seed: ''
    };
    gameTBD = true;
  }

  let hours = game.date.getHours();
  let hours_label = 'AM'
  if(hours >= 12){
    hours_label = 'PM';
  }

  if(hours > 12){
    hours -= 12;
  }
  if(hours == 0){
    hours = 12;
  }

  let today = Date.now();

  if (game.date > today && game.type !== 'playoff') {
    return (
      <div className='league-game-container' onClick={() => {
        window.location.href = `/games/${game.id}`
      }} key={game.id + '-game-container'}>
        <div className='league-game-team-names-and-scores'>
          <div className='league-game-team-names'>
            <p className='league-game-team-name' key={game.id + '-away-name'}>{ away_team.name.toUpperCase() }</p>
            <p className='league-game-team-name' key={game.id + '-home-name'}>{ home_team.name.toUpperCase() }</p>
          </div>
          <div className='league-game-team-records'>
            <p className='league-game-team-record' key={game.id + '-away-record'}>{ away_team.record }</p>
            <p className='league-game-team-record' key={game.id + '-home-record'}>{ home_team.record }</p>
          </div>
        </div>
        <div className='league-game-time-and-stats'>
          <p className='league-game-time'>{ utils.abbvWeekday(game.date.getDay()) }, { game.date.getMonth() + 1 }/{ game.date.getDate() }</p>
          <p className='league-game-time'>{ hours }:{ game.date.getMinutes() } { hours_label }</p>
          <p className='league-game-time'>{ utils.getLocationName(game.location, true) }</p>
        </div>
      </div>
    )
  } else if (game.type !== 'playoff') {
    return (
      <div className='league-game-container' onClick={() => {
        window.location.href = `/games/${game.id}`
      }} key={game.id + '-game-container'}>
        <div className='league-game-team-names-and-scores'>
          <div className='league-game-team-names'>
            <p className={ game.winner && game.winner === game.away_team_id ? 'league-game-team-name game-winner' : 'league-game-team-name' } key={game.id + '-away'}>{ away_team.name.toUpperCase() }</p>
            <p className={ game.winner && game.winner === game.home_team_id ? 'league-game-team-name game-winner' : 'league-game-team-name' } key={game.id + '-home'}>{ home_team.name.toUpperCase() }</p>
          </div>
          <div className='league-game-team-scores'>
            <p className={ game.winner && game.winner === game.away_team_id ? 'league-game-team-score game-winner' : 'league-game-team-score' } key={game.id + '-away-score'}>{ game.status === 'completed' ? game.score.away : '' }</p>
            <p className={ game.winner && game.winner === game.home_team_id ? 'league-game-team-score game-winner' : 'league-game-team-score' } key={game.id + '-home-score'}>{ game.status === 'completed' ? game.score.home : '' }</p>
          </div>
        </div>
        <div className='league-game-time-and-stats'>
          <p className='league-game-time'><b>{ game.status === 'completed' ? 'Final' : 'Pending' }</b></p>
          <p className='league-game-time'>{ game.date.getMonth() + 1 }/{ game.date.getDate() }</p>
        </div>
      </div>
    );
  } else if (game.date > today) {
    return (
      <div className='league-game-container' onClick={() => {
        window.location.href = `/games/${game.id}`
      }} key={game.id + '-game-container'}>
        <div className='league-game-team-names-and-seeds-and-scores'>
          <div className='league-game-team-names-and-seeds'>
            <div className='league-game-team-seeds'>
              <p className='league-game-team-seed' key={game.id + '-away-seed'}>{ away_team.seed }</p>
              <p className='league-game-team-seed' key={game.id + '-home-seed'}>{ home_team.seed }</p>
            </div>
            <div className='league-game-team-names'>
              <p className={ game.winner && game.winner === game.away_team_id ? 'league-game-team-name game-winner' : 'league-game-team-name' } key={game.id + '-away'}>{ away_team.name.toUpperCase() }</p>
              <p className={ game.winner && game.winner === game.home_team_id ? 'league-game-team-name game-winner' : 'league-game-team-name' } key={game.id + '-home'}>{ home_team.name.toUpperCase() }</p>
            </div>
          </div>
          <div className='league-game-team-scores-with-seeds'>
            <p className={ game.winner && game.winner === game.away_team_id ? 'league-game-team-score game-winner' : 'league-game-team-score' } key={game.id + '-away-score'}>{ game.status === 'completed' ? game.score.away : '' }</p>
            <p className={ game.winner && game.winner === game.home_team_id ? 'league-game-team-score game-winner' : 'league-game-team-score' } key={game.id + '-home-score'}>{ game.status === 'completed' ? game.score.home : '' }</p>
          </div>
        </div>
        <div className='league-game-time-and-stats'>
          <p className='league-game-time'>{ utils.abbvWeekday(game.date.getDay()) }, { game.date.getMonth() + 1 }/{ game.date.getDate() }</p>
          <p className='league-game-time'>{ hours }:{ game.date.getMinutes() } { hours_label }</p>
          <p className='league-game-time'>{ utils.getLocationName(game.location, true) }</p>
        </div>
      </div>
    )
  } else {
    return (
      <div className='league-game-container' onClick={() => {
        window.location.href = `/games/${game.id}`
      }} key={game.id + '-game-container'}>
        <div className='league-game-team-names-and-seeds-and-scores'>
          <div className='league-game-team-names-and-seeds'>
            <div className='league-game-team-seeds'>
              <p className='league-game-team-seed' key={game.id + '-away-seed'}>{ away_team.seed }</p>
              <p className='league-game-team-seed' key={game.id + '-home-seed'}>{ home_team.seed }</p>
            </div>
            <div className='league-game-team-names'>
              <p className={ game.winner && game.winner === game.away_team_id ? 'league-game-team-name game-winner' : 'league-game-team-name' } key={game.id + '-away'}>{ away_team.name.toUpperCase() }</p>
              <p className={ game.winner && game.winner === game.home_team_id ? 'league-game-team-name game-winner' : 'league-game-team-name' } key={game.id + '-home'}>{ home_team.name.toUpperCase() }</p>
            </div>
          </div>
          <div className='league-game-team-scores-with-seeds'>
            <p className={ game.winner && game.winner === game.away_team_id ? 'league-game-team-score game-winner' : 'league-game-team-score' } key={game.id + '-away-score'}>{ game.status === 'completed' ? game.score.away : '' }</p>
            <p className={ game.winner && game.winner === game.home_team_id ? 'league-game-team-score game-winner' : 'league-game-team-score' } key={game.id + '-home-score'}>{ game.status === 'completed' ? game.score.home : '' }</p>
          </div>
        </div>
        <div className='league-game-time-and-stats'>
          <p className='league-game-time'><b>{ game.status === 'completed' ? 'Final' : 'Pending' }</b></p>
          <p className='league-game-time'>{ game.date.getMonth() + 1 }/{ game.date.getDate() }</p>
          <p className='league-game-time'>{ utils.getLocationName(game.location, true) }</p>
        </div>
      </div>
    )
  }
}

export default GameContainer
