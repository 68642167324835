import React, { Component, useRef, useState, useEffect } from 'react';
import {Link, useParams } from "react-router-dom";
import NavBarMini from './NavBarMini.js';
import Footer from './Footer.js';
import LoadingAnimation from './LoadingAnimation.js';
import * as utils from '../common/Utils.js'

import { collection, query, where, getDoc, getDocs, setDoc, addDoc, updateDoc, doc, Timestamp, collectionGroup } from "firebase/firestore";
import { db } from '../firebase';

const PAST_SEASON = utils.getCurrentOrMostRecentSeason();
const SEASON_TO_ADD_PLAYER_TO = utils.getSeasonToAddPlayerTo();
const SEASON_START_DATE = utils.getUpcomingSeasonStartDate();

const DATABASE_SOURCE = utils.getDatabaseSource();

const NewPlayer = () => {
  const [league_morning_star, setLeague] = useState({
    games: [],
    players: [],
    teams: []
  });
  const [dataReady, setDataReady] = useState(false);
  const [isFormSubmitting, setIsFormSubmitting] = useState(false);

  useEffect(() => {
    getLeague();
  }, [])

  const getLeague = async () => {
    const docRef = doc(db, "league", DATABASE_SOURCE);
    const documentSnapshot = await getDoc(docRef);
    const league_morning_star = documentSnapshot.data();

    setLeague(league_morning_star);
    setDataReady(true);
  }

  const uniqueId = () => {
    const dateString = Date.now().toString(36);
    const randomness = Math.random().toString(36).substr(2);
    return dateString + randomness;
  }

  const savePlayer = async () => {
    setIsFormSubmitting(true);

    let playerID = uniqueId();

    let player = {
      age: document.getElementById('inputAge').value,
      email: document.getElementById('inputEmail').value,
      height: {
        feet: document.getElementById('inputHeightFeet').value,
        inches: document.getElementById('inputHeightInches').value
      },
      jersey_number: '',
      jersey_size: document.getElementById('inputJerseySize').value,
      first_name: document.getElementById('inputFirstName').value,
      last_name: document.getElementById('inputLastName').value,
      paid_fee: '',
      player_id: playerID,
      phone: document.getElementById('inputPhone').value,
      team_id: ''
    }

    league_morning_star['players'].push(player)

    await setDoc(doc(db, "league", DATABASE_SOURCE), league_morning_star);

    const docRef = await addDoc(collection(db, "signups"), {
      created_at: Timestamp.fromDate(new Date()),
      player_id: playerID,
      status: 'new',
      season: SEASON_TO_ADD_PLAYER_TO,
      paid: false
    });
    console.log("Document written with ID: ", docRef.id);

    window.location.href = `/signup/all/${playerID}`
  }

  function displayNewPlayerForm () {
    return (
      <>
        <form>
          <div className="form-row">
            <div className="form-group">
              <label for="inputFirstName">First Name</label>
              <input type="text" className="form-control" id="inputFirstName" required disabled={isFormSubmitting}></input>
            </div>
            <div className="form-group">
              <label for="inputLastName">Last Name</label>
              <input type="text" className="form-control" id="inputLastName" required disabled={isFormSubmitting}></input>
            </div>
          </div>
          <div className="form-group">
            <label for="inputEmail">Email</label>
            <input type="email" className="form-control" id="inputEmail" required disabled={isFormSubmitting}></input>
          </div>
          <div className="form-group">
            <label for="inputPhone">Phone Number</label>
            <input type="tel" className="form-control" id="inputPhone" required disabled={isFormSubmitting}></input>
          </div>
          <label for="inputHeightFeet">Height</label>
          <div className="form-row">
            <div className="form-group">
              <label for="inputHeightFeet">Feet</label>
              <select className="form-control" id="inputHeightFeet" defaultValue="6" disabled={isFormSubmitting}>
                <option value="5">5</option>
                <option value="6">6</option>
                <option value="7">7</option>
              </select>
            </div>
            <div className="form-group">
              <label for="inputHeightInches">Inches</label>
              <select className="form-control" id="inputHeightInches" defaultValue="0" disabled={isFormSubmitting}>
                <option value="0">0</option>
                <option value="1">1</option>
                <option value="2">2</option>
                <option value="3">3</option>
                <option value="4">4</option>
                <option value="5">5</option>
                <option value="6">6</option>
                <option value="7">7</option>
                <option value="8">8</option>
                <option value="9">9</option>
                <option value="10">10</option>
                <option value="11">11</option>
              </select>
            </div>
          </div>
          <div className="form-group">
            <label for="inputAge">Age</label>
            <input type="number" className="form-control" id="inputAge" required disabled={isFormSubmitting}></input>
          </div>
          <div className="form-group">
            <label for="inputJerseySize">Jersey Size</label>
            <select className="form-control" id="inputJerseySize" defaultValue="L" disabled={isFormSubmitting}>
                <option value="S">S</option>
                <option value="M">M</option>
                <option value="L">L</option>
                <option value="XL">XL</option>
                <option value="XXL">XXL</option>
              </select>
          </div>

          <button className="btn btn-dark" type="button" onClick={savePlayer} disabled={isFormSubmitting}>Create New Player</button>
        </form>
      </>
    )
  }

  if (dataReady == false) {
    return (
      <div className="Schedule">
        <NavBarMini alwaysShown={true} />
        <LoadingAnimation />
      </div>
    )
  }
  else {
    return (
      <div className="Schedule">
        <NavBarMini alwaysShown={true} />
        <div className="league-content">

          <div className="league-body">
            <p className="league-title-standard-secondary text-align-center">New Player</p>
            <a className="league-type" style={{textTransform: 'math-auto'}} href="/signup/returning">Already played in the league? Click here to sign up as a returning player</a>
            <div className="league-table-container schedule">
              { displayNewPlayerForm() }
            </div>
          </div>

        </div>

        <Footer/>
      </div>
    );
  }
}

export default NewPlayer;
