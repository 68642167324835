import React, { Component, useRef, useState, useEffect } from 'react';
import {Link} from "react-router-dom";
import NavBarMini from './NavBarMini.js';
import Footer from './Footer.js';
import LoadingAnimation from './LoadingAnimation';
import Bracket from './Bracket.js';
import GameContainer from './GameContainer';
import * as utils from '../common/Utils.js'
import { getAuth } from "firebase/auth";

import { collection, query, where, getDoc, getDocs, setDoc, addDoc, updateDoc, doc, Timestamp, collectionGroup } from "firebase/firestore";
import { db } from '../firebase';

const auth = getAuth();
const user = auth.currentUser

const SEASON = utils.getCurrentOrMostRecentSeason();
const UPCOMING_SEASON = utils.getUpcomingSeason();
const DATABASE_SOURCE = utils.getDatabaseSource();

const playoffRoundTagNames = ['playin', 'firstround', 'quarterfinals', 'semifinals', 'championship']
const playoffRoundNames = {
  'playin': 'Play-In',
  'firstround': 'First Round',
  'quarterfinals': 'Quarterfinals',
  'semifinals': 'Semifinals',
  'championship': 'Championship',
}
const playoffRoundClasses = {
  'playin': 'round_1_of_5',
  'firstround': 'round_2_of_5',
  'quarterfinals': 'round_3_of_5',
  'semifinals': 'round_4_of_5',
  'championship': 'round_5_of_5',
}
const CURRENT_ROUND = utils.getCurrentPlayoffRound();

// Used for registering swipe actions:
let startOfSwipeX = 0;
let endOfSwipeX = 0;
let startOfSwipeY = 0;
let endOfSwipeY = 0;

const Homepage = () => {
  const [league, setLeague] = useState({
    games: [],
    gameStats: [],
    players: [],
    seasonTeams: [],
    teams: []
  });
  const [dataReady, setDataReady] = useState(false);
  const [showOverallStandings, setShowOverallStandings] = useState(true);
  const [seasonData, setSeasonData] = useState({
    leaders: [],
    teams: []
  })

  useEffect(() => {
    getSeasonData();
    getLeague();
  }, [])

  const getSeasonData = async () => {
    const seasonRef = doc(db, 'seasons', SEASON);
    const seasonSnapshot = await getDoc(seasonRef);
    const seasonData = seasonSnapshot.data();
    setSeasonData(seasonData);
  }

  const getLeague = async () => {
    const docRef = doc(db, "league", DATABASE_SOURCE);
    const documentSnapshot = await getDoc(docRef);
    const league = documentSnapshot.data();

    // Only use games that are in this season
    const sortedGames = league.games.sort(function(a,b){
      return new Date(a.date) - new Date(b.date);
    });
    league.games = sortedGames.map((game) => {
      game.date = game.date.toDate();
      return game;
    });

    // Only use game stats from this season
    // Don't limit this for now...
    // const statsThisSeason = league.gameStats.filter((stat) => getGameForID(stat.game_id).season == SEASON)
    // league.gameStats = statsThisSeason

    // Only use teams that are in this season
    let teams = league.seasonTeams.filter((team) => team.season == SEASON)
    let updatedTeams = []
    for (const t of teams) {
      let teamData = league.teams.find((tm) => tm.id == t.team_id)
      let team = { ...teamData, ...t }

      updatedTeams.push(team)
    }
    league.teams = updatedTeams

    // utils.syncTestDatabaseWithProduction(league)

    addSeason3PlayoffGamesToDatabase (league)

    setLeague(league);
    setDataReady(true);
  }

  async function addSeason3PlayoffGamesToDatabase (league) {
    let playoffGames = league.games.filter((game) => game.type == 'playoff' && game.season == 3)

    if (playoffGames.length > 0) {
      return;
    }

    let playoffGameDefault = {
      away_team_id: '',
      date: '',
      home_team_id: '',
      location: '',
      playoff_tags: [],
      referees: [],
      score: {
        away: 0,
        home: 0
      },
      season: 3,
      status: 'scheduled',
      type: 'playoff',
      winner: ''
    }

    // FIRST ROUND

    // 1vs8 East
    let game1East = { ...playoffGameDefault }
    game1East.playoff_tags = ['first-round', '1vs8', 'east'];
    game1East.away_team_placeholder = '1st Seed';
    game1East.home_team_placeholder = '8th Seed';
    game1East.location = 'sbca';
    game1East.playoff_round_order = 1
    game1East.date = Timestamp.fromDate(new Date("March 11, 2025 6:30"))
    game1East.id = utils.createNewIDForGame();
    // 2vs7 East
    let game2East = { ...playoffGameDefault }
    game2East.playoff_tags = ['first-round', '2vs7', 'east'];
    game2East.away_team_placeholder = '2nd Seed';
    game2East.home_team_placeholder = '7th Seed';
    game2East.location = 'sbca';
    game2East.playoff_round_order = 2
    game2East.date = Timestamp.fromDate(new Date("March 12, 2025 6:30"))
    game2East.id = utils.createNewIDForGame();
    // 3vs6 East
    let game3East = { ...playoffGameDefault }
    game3East.playoff_tags = ['first-round', '3vs6', 'east'];
    game3East.away_team_placeholder = '3rd Seed';
    game3East.home_team_placeholder = '6th Seed';
    game3East.location = 'sbca';
    game3East.playoff_round_order = 3
    game3East.date = Timestamp.fromDate(new Date("March 13, 2025 6:30"))
    game3East.id = utils.createNewIDForGame();
    // 4vs5 East
    let game4East = { ...playoffGameDefault }
    game4East.playoff_tags = ['first-round', '4vs5', 'east'];
    game4East.away_team_placeholder = '4th Seed';
    game4East.home_team_placeholder = '5th Seed';
    game4East.location = 'sbca';
    game4East.playoff_round_order = 4
    game4East.date = Timestamp.fromDate(new Date("March 14, 2025 6:30"))
    game4East.id = utils.createNewIDForGame();
    // 1vs8 West
    let game1West = { ...playoffGameDefault }
    game1West.playoff_tags = ['first-round', '1vs8', 'west'];
    game1West.away_team_placeholder = '1st Seed';
    game1West.home_team_placeholder = '8th Seed';
    game1West.location = 'b&gc';
    game1West.playoff_round_order = 5
    game1West.date = Timestamp.fromDate(new Date("March 11, 2025 6:30"))
    game1West.id = utils.createNewIDForGame();
    // 2vs7 West
    let game2West = { ...playoffGameDefault }
    game2West.playoff_tags = ['first-round', '2vs7', 'west'];
    game2West.away_team_placeholder = '2nd Seed';
    game2West.home_team_placeholder = '7th Seed';
    game2West.location = 'b&gc';
    game2West.playoff_round_order = 6
    game2West.date = Timestamp.fromDate(new Date("March 12, 2025 6:30"))
    game2West.id = utils.createNewIDForGame();
    // 3vs6 West
    let game3West = { ...playoffGameDefault }
    game3West.playoff_tags = ['first-round', '3vs6', 'west'];
    game3West.away_team_placeholder = '3rd Seed';
    game3West.home_team_placeholder = '6th Seed';
    game3West.location = 'b&gc';
    game3West.playoff_round_order = 7
    game3West.date = Timestamp.fromDate(new Date("March 13, 2025 6:30"))
    game3West.id = utils.createNewIDForGame();
    // 4vs5 West
    let game4West = { ...playoffGameDefault }
    game4West.playoff_tags = ['first-round', '4vs5', 'west'];
    game4West.away_team_placeholder = '4th Seed';
    game4West.home_team_placeholder = '5th Seed';
    game4West.location = 'b&gc';
    game4West.playoff_round_order = 8
    game4West.date = Timestamp.fromDate(new Date("March 14, 2025 6:30"))
    game4West.id = utils.createNewIDForGame();

    // CONFERENCE SEMIFINALS
    // Winner of 1v8 vs Winner of 3v6 East
    let semi1East = { ...playoffGameDefault }
    semi1East.playoff_tags = ['conference-semifinal','east',
      'winner_' + game1East.id,
      'winner_' + game3East.id,
    ];
    semi1East.away_team_placeholder = 'Winner of 1v8';
    semi1East.home_team_placeholder = 'Winner of 3v6';
    semi1East.location = 'sbca';
    semi1East.playoff_round_order = 1
    semi1East.date = Timestamp.fromDate(new Date("March 18, 2025 6:30"))
    semi1East.id = utils.createNewIDForGame();
    // Winner of 2v7 vs Winner of 4v5 East
    let semi2East = { ...playoffGameDefault }
    semi2East.playoff_tags = ['conference-semifinal','east',
      'winner_' + game2East.id,
      'winner_' + game4East.id,
    ];
    semi2East.away_team_placeholder = 'Winner of 2v7';
    semi2East.home_team_placeholder = 'Winner of 4v5';
    semi2East.location = 'sbca';
    semi2East.playoff_round_order = 2
    semi2East.date = Timestamp.fromDate(new Date("March 19, 2025 6:30"))
    semi2East.id = utils.createNewIDForGame();
    // Winner of 1v8 vs Winner of 3v6 West
    let semi1West = { ...playoffGameDefault }
    semi1West.playoff_tags = ['conference-semifinal','west',
      'winner_' + game1West.id,
      'winner_' + game3West.id,
    ];
    semi1West.away_team_placeholder = 'Winner of 1v8';
    semi1West.home_team_placeholder = 'Winner of 3v6';
    semi1West.location = 'b&gc';
    semi1West.playoff_round_order = 3
    semi1West.date = Timestamp.fromDate(new Date("March 18, 2025 6:30"))
    semi1West.id = utils.createNewIDForGame();
    // Winner of 2v7 vs Winner of 4v5 West
    let semi2West = { ...playoffGameDefault }
    semi2West.playoff_tags = ['conference-semifinal','west',
      'winner_' + game2West.id,
      'winner_' + game4West.id,
    ];
    semi2West.away_team_placeholder = 'Winner of 2v7';
    semi2West.home_team_placeholder = 'Winner of 4v5';
    semi2West.location = 'b&gc';
    semi2West.playoff_round_order = 4
    semi2West.date = Timestamp.fromDate(new Date("March 19, 2025 6:30"))
    semi2West.id = utils.createNewIDForGame();

    // CONFERENCE FINALS
    // Winner of semis East
    let finalEast = { ...playoffGameDefault }
    finalEast.playoff_tags = ['conference-final','east',
      'winner_' + semi1East.id,
      'winner_' + semi2East.id,
    ];
    finalEast.away_team_placeholder = 'Winner of semi 1';
    finalEast.home_team_placeholder = 'Winner of semi 2';
    finalEast.location = 'sbca';
    finalEast.playoff_round_order = 1
    finalEast.date = Timestamp.fromDate(new Date("March 20, 2025 6:30"))
    finalEast.id = utils.createNewIDForGame();
    // Winner of semis West
    let finalWest = { ...playoffGameDefault }
    finalWest.playoff_tags = ['conference-final','west',
      'winner_' + semi1West.id,
      'winner_' + semi2West.id,
    ];
    finalWest.away_team_placeholder = 'Winner of semi 1';
    finalWest.home_team_placeholder = 'Winner of semi 2';
    finalWest.location = 'b&gc';
    finalWest.playoff_round_order = 2
    finalWest.date = Timestamp.fromDate(new Date("March 20, 2025 6:30"))
    finalWest.id = utils.createNewIDForGame();

    // FINALS
    // Conference champions
    let final = { ...playoffGameDefault }
    final.playoff_tags = ['final',
      'winner_' + finalEast.id,
      'winner_' + finalWest.id,
    ];
    final.away_team_placeholder = 'Eastern Champion';
    final.home_team_placeholder = 'Western Champion';
    final.location = 'condon';
    final.date = Timestamp.fromDate(new Date("March 21, 2025 18:30"))
    final.id = utils.createNewIDForGame();

    league.games.push(game1East)
    league.games.push(game2East)
    league.games.push(game3East)
    league.games.push(game4East)
    league.games.push(game1West)
    league.games.push(game2West)
    league.games.push(game3West)
    league.games.push(game4West)
    league.games.push(semi1East)
    league.games.push(semi2East)
    league.games.push(semi1West)
    league.games.push(semi2West)
    league.games.push(finalEast)
    league.games.push(finalWest)
    league.games.push(final)

    await setDoc(doc(db, "league", "league_morning_star_2_test"), league);
  }

  async function addSeason2PlayoffGamesToDatabase (league) {
    let playoffGames = league.games.filter((game) => game.type == 'playoff' && game.season == 2)

    if (playoffGames.length > 0) {
      return;
    }
    let playoffGameDefault = {
      away_team_id: '',
      home_team_id: '',
      playoff_tags: [],
      referees: [],
      score: {
        away: 0,
        home: 0
      },
      season: 2,
      type: 'playoff',
      winner: ''
    }

    // 10v11
    let playin10v11 = { ...playoffGameDefault }
    playin10v11.playoff_tags = ['playin', '10v11'];
    playin10v11.away_team_placeholder = '10th Seed';
    playin10v11.home_team_placeholder = '11th Seed';
    playin10v11.date = Timestamp.fromDate(new Date("August 12, 2024 6:30"))
    playin10v11.id = utils.createNewIDForGame();

    // 9v12 play-in
    let playin9v12 = { ...playoffGameDefault }
    playin9v12.playoff_tags = ['playin', '9v12'];
    playin9v12.away_team_placeholder = '9th Seed';
    playin9v12.home_team_placeholder = '12th Seed';
    playin9v12.date = Timestamp.fromDate(new Date("August 12, 2024 19:30"))
    playin9v12.id = utils.createNewIDForGame();

    // 4vlow_playin
    let firstround4vlow_playin = { ...playoffGameDefault }
    firstround4vlow_playin.playoff_tags = ['firstround', '4vlow_playin'];
    firstround4vlow_playin.away_team_placeholder = '4th Seed';
    firstround4vlow_playin.playoff_round_order = 1
    firstround4vlow_playin.date = Timestamp.fromDate(new Date("August 13, 2024 6:30"))
    firstround4vlow_playin.id = utils.createNewIDForGame();

    // 3vhigh_playin
    let firstround3vhigh_playin = { ...playoffGameDefault }
    firstround3vhigh_playin.playoff_tags = ['firstround', '3vhigh_playin'];
    firstround3vhigh_playin.away_team_placeholder = '3rd Seed';
    firstround3vhigh_playin.playoff_round_order = 3
    firstround3vhigh_playin.date = Timestamp.fromDate(new Date("August 13, 2024 19:30"))
    firstround3vhigh_playin.id = utils.createNewIDForGame();

    // 6v7
    let firstround6v7 = { ...playoffGameDefault }
    firstround6v7.playoff_tags = ['firstround', '6v7'];
    firstround6v7.away_team_placeholder = '6th Seed';
    firstround6v7.home_team_placeholder = '7th Seed';
    firstround6v7.playoff_round_order = 2
    firstround6v7.date = Timestamp.fromDate(new Date("August 14, 2024 6:30"))
    firstround6v7.id = utils.createNewIDForGame();

    // 5v8
    let firstround5v8 = { ...playoffGameDefault }
    firstround5v8.playoff_tags = ['firstround', '5v8'];
    firstround5v8.away_team_placeholder = '5th Seed';
    firstround5v8.home_team_placeholder = '8th Seed';
    firstround5v8.playoff_round_order = 4
    firstround5v8.date = Timestamp.fromDate(new Date("August 14, 2024 19:30"))
    firstround5v8.id = utils.createNewIDForGame();

    // XXXvYYY
    let quarterfinals1 = { ...playoffGameDefault }
    quarterfinals1.playoff_tags = ['quarterfinals',
      "winner_" + firstround4vlow_playin.id,
      "winner_" + firstround6v7.id
    ];
    quarterfinals1.date = Timestamp.fromDate(new Date("August 15, 2024 6:30"))
    quarterfinals1.id = utils.createNewIDForGame();

    // AAAvBBB
    let quarterfinals2 = { ...playoffGameDefault }
    quarterfinals2.playoff_tags = ['quarterfinals',
      "winner_" + firstround3vhigh_playin.id,
      "winner_" + firstround5v8.id
    ];
    quarterfinals2.date = Timestamp.fromDate(new Date("August 15, 2024 19:30"))
    quarterfinals2.id = utils.createNewIDForGame();

    // 2vCCC
    let semifinals1 = { ...playoffGameDefault }
    semifinals1.playoff_tags = ['semifinals',
      '2',
      "winner_" + quarterfinals2.id
    ];
    semifinals1.away_team_placeholder = '2nd Seed';
    semifinals1.playoff_round_order = 2
    semifinals1.date = Timestamp.fromDate(new Date("August 16, 2024 6:30"))
    semifinals1.id = utils.createNewIDForGame();

    // 1vZZZ
    let semifinals2 = { ...playoffGameDefault }
    semifinals2.playoff_tags = ['semifinals',
      '1',
      "winner_" + quarterfinals1.id
    ];
    semifinals2.away_team_placeholder = '1st Seed';
    semifinals2.playoff_round_order = 1
    semifinals2.date = Timestamp.fromDate(new Date("August 16, 2024 19:30"))
    semifinals2.id = utils.createNewIDForGame();

    // DDDvWWW
    let championship = { ...playoffGameDefault }
    championship.playoff_tags = ['championship',
      "winner_" + semifinals1.id,
      "winner_" + semifinals2.id
    ];
    championship.date = Timestamp.fromDate(new Date("August 18, 2024 14:30"))
    championship.id = utils.createNewIDForGame();

    league.games.push(playin10v11)
    league.games.push(playin9v12)
    league.games.push(firstround4vlow_playin)
    league.games.push(firstround3vhigh_playin)
    league.games.push(firstround6v7)
    league.games.push(firstround5v8)
    league.games.push(quarterfinals1)
    league.games.push(quarterfinals2)
    league.games.push(semifinals1)
    league.games.push(semifinals2)
    league.games.push(championship)

    await setDoc(doc(db, "league", test), league);
  }

  async function addPlayoffGamesToDatabase (league) {
    let oneSemiFinalGame = league.games.filter((game) => game.type == 'playoff')
    if (oneSemiFinalGame.length !== 1) {
      return;
    }
    oneSemiFinalGame = oneSemiFinalGame[0];

    let oneDay = (1 * 24 * 60 * 60 * 1000);

    // 2v7
    let game2 = { ...oneSemiFinalGame }
    game2.playoff_tags = ['quarterfinal', '2v7'];
    game2.away_team_id = 'francis-xavier';
    game2.home_team_id = 'isaac-jogues';

    // 3v6
    let game3 = { ...oneSemiFinalGame }
    game3.playoff_tags = ['quarterfinal', '3v6'];
    game3.away_team_id = 'augustine';
    game3.home_team_id = 'pius-v';

    // 4v5
    let game4 = { ...oneSemiFinalGame }
    game4.playoff_tags = ['quarterfinal', '4v5'];
    game4.away_team_id = 'maximilian-kolbe';
    game4.home_team_id = 'john-paul-ii';

    league.games.push(game2)
    league.games.push(game3)
    league.games.push(game4)

    // await setDoc(doc(db, "league", 'league_data_test'), league);
  }

  function displayTeam(team, place) {
    return (
        <tr id={team.id + "-team-row"} key={team.id + "-team-row"}>
          <td className="league-table-data-name user-name no-wrap"><span>{ place + '. ' }</span><a className="unstyled-link" href={"/teams/" + team.id }>{ team.name.toUpperCase() }</a></td>
          <td className="league-table-data desktop-only"><span>{ team.wins }</span></td>
          <td className="league-table-data desktop-only"><span>{ team.losses }</span></td>
          <td className="league-table-data wide-fixed mobile-only"><span>{ team.wins }-{ team.losses }</span></td>
          <td className="league-table-data wide"><span>{ team.winPercentage }</span></td>
          <td className="league-table-data wide desktop-only"><span>{ team.pointsFor }</span></td>
          <td className="league-table-data wide desktop-only"><span>{ team.pointsAgainst }</span></td>
          <td className="league-table-data wide desktop-only"><span>{ team.pointsFor - team.pointsAgainst }</span></td>
          <td className="league-table-data wide-fixed desktop-only"><span>{ team.streak }</span></td>
        </tr>
    );
  }

  function displayTeams (teams) {
    if (!teams){
      return;
    }

    const team_elements = teams.map((team, place) => displayTeam(team, place+1) )

    return team_elements;
  }

  function displayGameThisWeek (game) {
    // let gameID = `${game.away_team_id}-vs-${game.home_team_id}-${game.date.getMonth() + 1}-${game.date.getDate()}-${game.date.getFullYear()}`

    let gameTBD = false;

    let away_team;
    let home_team;

    if (game.away_team_id) {
      away_team = utils.getTeamForID(league.teams, game.away_team_id);
    } else {
      const placeholderName = game.away_team_placeholder
      away_team = {
        seed: '',
        name: placeholderName || 'TBD'
      };
      gameTBD = true;
    }

    if (game.home_team_id) {
      home_team = utils.getTeamForID(league.teams, game.home_team_id);
    } else {
      const placeholderName = game.home_team_placeholder
      home_team = {
        seed: '',
        name: placeholderName || 'TBD'
      };
      gameTBD = true;
    }

    let hours = game.date.getHours();
    let hours_label = 'AM'
    if(hours >= 12){
      hours_label = 'PM';
    }

    if(hours > 12){
      hours -= 12;
    }
    if(hours == 0){
      hours = 12;
    }

    return (
      <div className="league-game-container-homepage"  onClick={() => {
        window.location.href = `/games/${game.id}`
      }} key={game.id + '-game-container'}>
        <p className="league-title-skinny-small" key={game.id + "-date"}>{ game.date.toDateString() } @ { hours }:{ game.date.getMinutes() } { hours_label }</p>
        <p className="league-game-location" key={game.id + "-location"}>{ utils.getLocationName(game.location) }</p>
        <p key={game.id}>{ away_team.name.toUpperCase() } vs. { home_team.name.toUpperCase() }</p>
      </div>
    )
  }

  function anyGamesInLastDay () {
    let today = Date.now();
    let oneDayAgo = today - (1 * 24 * 60 * 60 * 1000);

    let gamesInTheLastDay = league.games.filter((game) => game.date < today && game.date > oneDayAgo && game.status == 'completed')
    return gamesInTheLastDay.length > 0;
  }

  function displayFinishedGamesLastDay () {
    let today = Date.now();
    let oneDayAgo = today - (1 * 24 * 60 * 60 * 1000);

    let gamesInTheLastDay = league.games.filter((game) => game.date < today && game.date > oneDayAgo && game.status == 'completed')
    let gameElements = gamesInTheLastDay.map((game) => displayFinishedGameThisWeek(game) )
    return gameElements;
  }

  function displayGamesThisWeek () {
    let today = Date.now();
    let sevenDays = today + (7 * 24 * 60 * 60 * 1000);

    let gamesThisWeek = league.games.filter((game) => game.date > today && game.date < sevenDays && !['completed', 'postponed'].includes(game.status))
    let finishedGames = league.games.filter((game) => game.date <= today || game.status === 'completed' )

    if (league.games == 0) {
      return <p>Waiting for games to be added to website...</p>
    }
    if (gamesThisWeek.length == 0) {
      return <p>No upcoming games in the next week</p>
    }
    let sortedGamesThisWeek = gamesThisWeek.sort((a, b) => (a.date < b.date) ? -1 : 1)

    let gameElements = sortedGamesThisWeek.map((game, place) => displayGameThisWeek(game) )
    return gameElements;
  }

  function displayFinishedGameThisWeek (game) {
    return (
      <GameContainer league={league} gameID={game.id}></GameContainer>
    )
  }

  function displayPlayoffGame (game) {
    let gameTBD = false;

    let away_team;
    let home_team;
    if (game.away_team_id) {
      away_team = utils.getTeamForID(league.teams, game.away_team_id);
    } else {
      const placeholderName = game.away_team_placeholder
      away_team = {
        seed: '',
        name: placeholderName || 'TBD'
      };
      gameTBD = true;
    }

    if (game.home_team_id) {
      home_team = utils.getTeamForID(league.teams, game.home_team_id);
    } else {
      const placeholderName = game.home_team_placeholder
      home_team = {
        seed: '',
        name: placeholderName || 'TBD'
      };
      gameTBD = true;
    }

    let hours = game.date.getHours();
    let hours_label = 'AM'
    if(hours >= 12){
      hours_label = 'PM';
    }

    if(hours > 12){
      hours -= 12;
    }
    if(hours == 0){
      hours = 12;
    }

    let today = Date.now();


    return (
      <GameContainer league={league} gameID={game.id}></GameContainer>
    )
  }

  function displayPlayoffGames (round) {
    // let games = []
    // let rankedTeams = league.teams
    // let totalTeams = rankedTeams.length

    // let roundNameMap = {
    //   '4': 'quarterfinal',
    //   '2': 'semifinal',
    //   '1': 'championship'
    // }
    // let roundName = roundNameMap[numberOfGames.toString()]

    let gamesThisRound = league.games.filter((game) => game.type == 'playoff' && game.playoff_tags && game.playoff_tags.includes(round) && game.season == SEASON);

    let sortedGames = gamesThisRound.sort(function(a,b) {
      let orderA = a.playoff_round_order || 0;
      let orderB = b.playoff_round_order || 0;
      if (orderA < orderB) {
        return -1;
      } else if (orderB < orderA) {
        return 1;
      } else {
        return 0;
      }
    })

    // let gamesThisRoundWithTeams = gamesThisRound.filter((game) => game.away_team_id !== '' && game.home_team_id !== '')
    // let gamesThisRoundWithoutTeams = gamesThisRound.filter((game) => game.away_team_id == '' || game.home_team_id == '')
    let gameElements = sortedGames.map((game, place) => displayPlayoffGame(game))
    return (
      <>
        {gameElements}
      </>
    );
  }

  function displayPlayoffsButtons () {
    let playoffButtons = [];

    const playoffRounds = playoffRoundTagNames.length - (league.teams.length / 2) + 1
    const numberPlayoffRounds = playoffRoundTagNames.length - playoffRounds;
    for (let i = playoffRounds; i < playoffRoundTagNames.length; i++) {
      let playoffRoundTagName = playoffRoundTagNames[i];
      let playoffRoundName = playoffRoundNames[playoffRoundTagName];

      let buttonClass = '';
      if (league.teams.length == 8){
        if (playoffRoundTagName == 'championship') {
          buttonClass = 'btn-dark';
        }
      } else if (league.teams.length == 12) {
        if (playoffRoundTagName == 'championship') {
          buttonClass = 'btn-dark';
        }
      } else if (league.teams.length == 16) {
        if (playoffRoundTagName == CURRENT_ROUND) {
          buttonClass = 'btn-dark';
        }
      }

      let roundClassName = "round_" + (i + 1 - playoffRounds) + "_of_" + numberPlayoffRounds

      playoffButtons.push(
        <div className='league-bracket-round-button' key={ 'button_for_' + roundClassName }>
          <a id={ playoffRoundTagName + "Button" } type="button" className={ "btn " + buttonClass } onClick={ () => { clickPlayoffRoundButton(playoffRoundTagName, roundClassName, playoffRounds) } }>{ playoffRoundName }</a>
        </div>
      )
    }

    return (
      <div className='league-bracket-round-buttons scroll-on-overflow'>
        { playoffButtons }
      </div>
    )
  }

  function clickPlayoffRoundButton(playoffRoundTagName, roundClassName, playoffRounds){
    let leagueBracket = document.getElementsByClassName('league-bracket')[0];
    leagueBracket.classList = '';
    leagueBracket.classList.add('league-bracket');
    leagueBracket.classList.add(roundClassName);

    let button = document.getElementById(playoffRoundTagName + 'Button');
    button.classList.add('btn-dark')

    for (let j = playoffRounds; j < playoffRoundTagNames.length; j++) {
      let playoffOtherRoundTagName = playoffRoundTagNames[j];
      if (playoffRoundTagName == playoffOtherRoundTagName) {
        continue;
      }

      let playoffRoundButton = document.getElementById(playoffOtherRoundTagName + 'Button');
      playoffRoundButton.classList.remove('btn-dark')
    }
  }

  function displayPlayoffs () {
    return (
      <>
        { displayPlayoffRounds() }
      </>
    );
  }

  function swipePlayoffRound (swipeDirection, rounds) {
    let playinButton = document.getElementById('playinButton');
    let firstroundButton = document.getElementById('firstroundButton');
    let quarterfinalsButton = document.getElementById('quarterfinalsButton');
    let semifinalsButton = document.getElementById('semifinalsButton');
    let championshipButton = document.getElementById('championshipButton');

    let roundButtons = [playinButton, firstroundButton, quarterfinalsButton, semifinalsButton, championshipButton]

    for (let i = 0; i < roundButtons.length; i++) {
      let roundButton = roundButtons[i]
      if (roundButton.classList.contains('btn-dark')){
        if (swipeDirection == 'right' && i >= roundButtons.length - 1) {
        } else if (swipeDirection == 'left' && i <= roundButtons.length - rounds) {
        } else if (swipeDirection == 'left') {
          roundButtons[i-1].scrollIntoView({ behavior: "smooth", block: "nearest", inline: "nearest" });
          roundButtons[i-1].click();
        } else if (swipeDirection == 'right') {
          roundButtons[i+1].scrollIntoView({ behavior: "smooth", block: "nearest", inline: "nearest" });
          roundButtons[i+1].click();
        }
        break;
      }
    }
  }

  function checkDirectionOfSwipe (rounds) {
    if (Math.abs(startOfSwipeX - endOfSwipeX) < 10) {
      return;
    }
    if (Math.abs(startOfSwipeX - endOfSwipeX) < Math.abs(startOfSwipeY - endOfSwipeY)) {
      return;
    }

    if (endOfSwipeX < startOfSwipeX) {
      swipePlayoffRound('right', rounds)
    } else if (endOfSwipeX > startOfSwipeX) {
      swipePlayoffRound('left', rounds)
    }
  }

  function displayPlayoffRounds () {
    let divClass = '';
    if (SEASON == 1){
      return (
        <div className='league-bracket round_3_of_3' onTouchStart={(e) => {
          startOfSwipeX = e.changedTouches[0].screenX;
          startOfSwipeY = e.changedTouches[0].screenY;
        }} onTouchEnd={(e) => {
          endOfSwipeX = e.changedTouches[0].screenX;
          endOfSwipeY = e.changedTouches[0].screenY;
          let rounds = 3
          checkDirectionOfSwipe(rounds)
        }}>
          { displayPlayoffsForRounds(['quarterfinals', 'semifinals', 'championship']) }
        </div>
      );
    } if (SEASON == 2){
      return (
        <div className={ `league-bracket ${playoffRoundClasses[CURRENT_ROUND]}` } onTouchStart={(e) => {
          startOfSwipeX = e.changedTouches[0].screenX;
          startOfSwipeY = e.changedTouches[0].screenY;
        }} onTouchEnd={(e) => {
          endOfSwipeX = e.changedTouches[0].screenX;
          endOfSwipeY = e.changedTouches[0].screenY;
          let rounds = 5
          checkDirectionOfSwipe(rounds)
        }}>
          { displayPlayoffsForRounds(['playin', 'firstround', 'quarterfinals', 'semifinals', 'championship']) }
        </div>
      );
    }
  }

  function displayPlayoffsForRounds(rounds) {
    let playoffBracket = rounds.map((round) => displayPlayoffRound(round))

    return (
      <>
        { playoffBracket }
      </>
    );
  }

  function displayPlayoffRound(round) {
    let roundNameMap = {
      'playin': 'Play-In',
      'firstround': 'First Round',
      'quarterfinals': 'Quarterfinals',
      'semifinals': 'Semifinals',
      'championship': 'Championship'
    }
    const roundName = roundNameMap[round]

    let roundLocation = 'South Boston Catholic Academy'

    if (SEASON == 1) {
      let roundLocationMap = {
        'quarterfinals': 'South Boston Catholic Academy',
        'semifinals': 'Joseph P. Tynan School',
        'championship': 'South Boston Catholic Academy'
      }

      roundLocation = roundLocationMap[round]
    } else if (SEASON == 2) {
      let roundLocationMap = {
        'playin': 'South Boston Catholic Academy',
        'firstround': 'South Boston Catholic Academy',
        'quarterfinals': 'South Boston Catholic Academy',
        'semifinals': 'South Boston Catholic Academy',
        'championship': 'South Boston Catholic Academy'
      }

      roundLocation = roundLocationMap[round]
    }

    return (
      <div className='league-bracket-round'>
        <h4 className='text-align-center'>{ roundName }</h4>
        <p className='text-align-center'>{ roundLocation }</p>
        <div className='league-bracket-round-games'>
          { displayPlayoffGames(round) }
        </div>
      </div>
    )
  }

  function showAverageStatRow(leader) {
    let statLabelAbbv = {
      'assists': 'APG',
      'points': 'PPG',
      'rebounds': 'RPG',
      'blocks': 'BPG',
      'steals': 'SPG'
    }

    return (
      <div className='league-game-player-stat-group' key={leader.id + '-leader-' + leader.stat }>
        <p className='league-game-player-name'><a className="unstyled-link" href={"/players/" + leader.id }>{ leader.name }</a> <span className='league-season-average-label'>({ leader.teamName })</span></p>
        <p className='league-game-player-stat'>{ parseFloat(leader.value.toFixed(1)) } <span className='league-season-average-label'>{ statLabelAbbv[leader.stat] }</span></p>
      </div>
    )
  }

  function showTopSeasonStats (stat, season = 0) {
    let statLabel = {
      'assists': 'Assists',
      'points': 'Points',
      'rebounds': 'Rebounds',
      'blocks': 'Blocks',
      'steals': 'Steals'
    }

    const statLeaders = seasonData.leaders.filter((leader) => leader.stat == stat);

    if (statLeaders.length == 0) {
      return;
    } else {
      return (
        <div className='league-player-display'>
          <p className="league-title-standard-small text-center">{ statLabel[stat] }</p>
          <div className='league-player-container'>
            <div className='league-game-player-stats'>
              { statLeaders.map((leader) => showAverageStatRow(leader) ) }
            </div>
          </div>
        </div>
      )
    }
  }

  function showStandings (teams) {
    return (
      <div className="league-table-container scroll-on-overflow">
        <table className="table league-table">
          <thead className="league-table-heading-hole">
            <tr>
              <th className="league-table-heading-name"><span>TEAM</span></th>
              <th className="league-table-heading hole"><span>W</span></th>
              <th className="league-table-heading hole"><span>L</span></th>
              <th className="league-table-heading hole"><span>PCT</span></th>
              <th className="league-table-heading hole"><span>PF</span></th>
              <th className="league-table-heading hole"><span>PA</span></th>
              <th className="league-table-heading hole"><span>+/-</span></th>
              <th className="league-table-heading hole"><span>Strk</span></th>
            </tr>
          </thead>
          <thead className="league-table-heading-total">
            <tr>
              <th className="league-table-heading-name"><span>TEAM</span></th>
              <th className="league-table-heading-small"><span>W - L</span></th>
              <th className="league-table-heading-small"><span>PCT</span></th>
            </tr>
          </thead>
          <tbody>
            { displayTeams(teams) }
          </tbody>
        </table>
      </div>
    )
  }

  function displayOverallStandings () {
    return (
      <div>
        <p className="league-title-standard-secondary text-align-center">League Morning Star</p>
        { showStandings(seasonData.teams) }
      </div>
    )
  }

  function displayConferenceStandings (conference) {
    let conferenceTeams = seasonData.teams.filter((team) => team.conference == conference)
    let conferenceTitle = conference == 'east' ? 'Eastern Conference' : 'Western Conference'

    return (
      <div>
        <p className="league-title-standard-secondary text-align-center">{ conferenceTitle }</p>
        { showStandings(conferenceTeams) }
      </div>
    )
  }

  function toggleToShowLeagueStandings () {
    setShowOverallStandings(true);
  }
  function toggleToShowConferenceStandings () {
    setShowOverallStandings(false);
  }

  if (dataReady == false) {
    return (
      <div className="Homepage">
        <NavBarMini alwaysShown={true} />
        <LoadingAnimation />
      </div>
    )
  }
  else {
    return (
      <div className="Homepage">
        <NavBarMini alwaysShown={true} />
        <div className="league-content">
          <div className="league-header">
            <div className="league-header-top">
              <div className="league-header-logo">
                <img className="league-logo" src="league.png"></img>
                <a id="mass-times-link" className="league-type" href="https://www.gateofheavenstbrigid.org/#MassTimes">Mass Times</a>
                <img className="league-logo" src="medal.png"></img>
              </div>
              <p className="league-type-secondary"></p>
            </div>
          </div>

          <div className="league-body">
            <div style={{margin: '20px', textAlign: 'center'}}>
              <a className="league-type" style={{textTransform: 'math-auto'}} href="/our-story">The Story of League Morning Star</a>
            </div>
            <a className="btn league-btn-primary" href='prayer-intentions/new'>Write a Prayer Intention</a>
          </div>

          { anyGamesInLastDay() ? <div className="league-body">
            <p className="league-title-standard-secondary text-align-center">Recent Scores</p>
            <div className="schedule">
              { displayFinishedGamesLastDay() }
            </div>
          </div> : '' }

          <div className="league-body">
            { utils.showSignUps() ?
            <>
            <p className="league-title-standard-secondary text-align-center">Sign up for Season III</p>
            <p>With the 3rd season of League Morning Star coming up soon, we will have the biggest shake up to date. Sign up now to secure your spot!</p>
            <a type="button" className="btn btn-success" href='signup'>Click here to sign up!</a>
            <br></br>
            </>
            : '' }

            { utils.showPlayoffBracket() ? <Bracket league={ league } playoffGames={ utils.getPlayoffGames(league, SEASON) } showPlaceholders={ !utils.playoffsStarted(league) }></Bracket> : <></> }


            { utils.playoffsStarted(league) ?
              <>
              </> :
              <>
                <p className="league-title-standard-secondary text-align-center">Upcoming Games</p>
                <a className="league-type league-mini-header-link" href="/schedule">View All Games</a>
                <div className="league-table-container">
                  { displayGamesThisWeek() }
                </div>
              </>
            }
          </div>

          <div className="league-body">
            <p className="league-title-standard text-align-center">Standings</p>

            <div className="btn-group league-radio-btn-group" role="group" aria-label="League/conference radio toggle button group">
              <input type="radio" className="btn-check" name="btnradio" id="btnLeague" autoComplete="off" defaultChecked onClick={ () => { toggleToShowLeagueStandings() }}></input>
              <label className="btn league-btn-outline-primary" htmlFor="btnLeague">League</label>

              <input type="radio" className="btn-check" name="btnradio" id="btnConference" autoComplete="off" onClick={ () => { toggleToShowConferenceStandings() }}></input>
              <label className="btn league-btn-outline-primary" htmlFor="btnConference">Conference</label>
            </div>

            { showOverallStandings ?
              displayOverallStandings() :
              <>
                { displayConferenceStandings('west') }
                { displayConferenceStandings('east') }
              </>
            }

            { utils.showSeasonLeaders(league) ?
            <div className="league-game-stats-container">
              <p className="league-title-skinny-medium"><b>Season Leaders</b></p>
              { showTopSeasonStats('points', SEASON) }
              { showTopSeasonStats('rebounds', SEASON) }
              { showTopSeasonStats('assists', SEASON) }
              { showTopSeasonStats('blocks', SEASON) }
              { showTopSeasonStats('steals', SEASON) }
            </div> : ''
            }
          </div>
        </div>
        <Footer/>
      </div>
    );
  }
}

export default Homepage;
